import React, { useEffect, useState, useRef, Fragment, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
//formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
// Import React FilePond
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { deepCopy } from "../../../../helpers/format_helper";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import { Popover } from "antd";
import CKEditorCustom from "../../../../Components/Common/CKEditorCustom";
import withRouter from "../../../../Components/Common/withRouter";
import ScrollToError from "../../../../Components/Common/ScrollToError";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FormBlogOrigin = (props) => {
  const { router, formRef, dataDefault = {}, onHandleAfterSubmit = () => {} } = props;
  const { t } = router;
  const dispatch = useDispatch();
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));

  const refForm = useRef();
  const [saveData, setSaveData] = useState(dataDefault);

  useImperativeHandle(formRef, () => ({
    onSubmitForm: async () => {
      await refForm.current.submitForm();
      const isValid = await refForm.current.isValid;
      const values = refForm.current.values;

      if (!values.tags) {
        values.meta_data && (values.meta_data.keywords = null);
      } else {
        values.meta_data && (values.meta_data.keywords = values.tags);
      }

      if (isValid) {
        return values;
      } else {
        return null;
      }
    },
  }));

  const validation = {
    enableReinitialize: true,
    initialValues: {
      image: dataDefault?.image ?? null,
      title: dataDefault?.title ?? "",
      content: dataDefault?.content ?? "",
      description: dataDefault?.description ?? "",
      tags: dataDefault?.tags ?? null,
      category: dataDefault?.category ?? null,
      slug: dataDefault?.slug ?? null,
      meta_data: dataDefault?.meta_data ?? null,
      blog_type: dataDefault?.blog_type ?? "blog",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Please Enter Blog Title")),
      content: Yup.string().required(t("Please Enter Blog Content")),
      description: Yup.string().required(t("Please Enter Blog Description")),
    }),
    onSubmit: async (values) => {
      onHandleAfterSubmit(values);
    },
  };

  const handleChangeMetaData = (field, value, meta_data) => {
    const metaData = deepCopy(meta_data);
    if (value) {
      metaData[field] = value;
    } else {
      delete metaData[field];
    }
    refForm.current.setFieldValue("meta_data", metaData);
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
        onSubmit={validation.onSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="d-flex gap-3">
              <div className="flex-grow-1">
                <Card className="p-4">
                  <SelectTheme
                    name="category"
                    placeholder="Select blog category"
                    remote={true}
                    selectDataOrigin={true}
                    isObjectValueSelect={true}
                    path="blog/categories"
                    label={t("Category")}
                  />
                  <SelectTheme
                    name="blog_type"
                    placeholder="Select type blog"
                    label={t("Type")}
                    options={[
                      { label: "Blog", value: "blog" },
                      { label: "Page", value: "page" },
                    ]}
                  />
                  <UploadTheme label="Image" name="image" />
                </Card>
                <Card id="basic-information">
                  <CardBody>
                    <InputTheme
                      name="title"
                      placeholder="Enter blog title"
                      label="Title"
                      onTrigger={(value) => {
                        setSaveData((data) => ({ ...data, title: value }));
                      }}
                    />

                    <InputTheme
                      name="description"
                      placeholder="Enter blog description"
                      label="Description"
                      onTrigger={(value) => {
                        setSaveData((data) => ({ ...data, description: value }));
                      }}
                    />

                    <div id="blog-content" className="mb-3">
                      <h5 className="fs-14 mb-1 hstack justify-content-between">
                        {t("Content")}
                        <Popover
                          content={
                            <div style={{ width: "850px", height: "400px", overflow: "auto" }}>
                              {values.content ? (
                                <div className="ck-content" dangerouslySetInnerHTML={{ __html: values.content }} />
                              ) : (
                                ""
                              )}
                            </div>
                          }
                          title={t("Preview")}
                          placement="right"
                        >
                          <div className="hstack gap-2">
                            <span>
                              <i className="ri-eye-fill align-bottom color-sub-text-secondary fs-18" />
                            </span>
                          </div>
                        </Popover>
                      </h5>
                      <div
                        style={{
                          border: `1px solid ${errors.content && touched.content ? "#f06548" : "#d1d1d1"}`,
                          backgroundColor: "#fff",
                        }}
                      >
                        <CKEditorCustom
                          config={{
                            language: "vi",
                            resize: {
                              minHeight: "300px",
                              maxHeight: "600px",
                            },
                          }}
                          imagePrefix="blogs"
                          value={values.content}
                          onChangeValue={(data) => {
                            setFieldValue("content", data);
                            setSaveData((d) => ({ ...d, content: data }));
                          }}
                        />
                      </div>
                      {errors.content && touched.content && (
                        <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
                          {errors.content}
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <h5 className="card-title">{t("SEO")}</h5>
                    <InputTheme name="slug" placeholder="this-is-blog-title" label="Slug" />
                    <InputTheme
                      id="title_tag"
                      isForm={false}
                      placeholder="Enter Title Tag"
                      label="Title Tag"
                      value={values.meta_data?.title}
                      onChange={(value) => {
                        handleChangeMetaData("title", value, values.meta_data || {});
                      }}
                    />
                    <InputTheme
                      id="description_tag"
                      isForm={false}
                      placeholder="Enter Description Tag"
                      label="Description Tag"
                      value={values.meta_data?.description}
                      onChange={(value) => {
                        handleChangeMetaData("description", value, values.meta_data || {});
                      }}
                    />
                    <InputTheme
                      name="tags"
                      label="Keywords Tag"
                      type="tags"
                      placeholder="Enter keywords and press enter"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(FormBlogOrigin);
