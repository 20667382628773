// const getEnvValue = (envVarProduction, envVarDevelopment, envVarLocal, fallback) => {
//   switch (process.env.NODE_ENV) {
//     case "production":
//       return process.env[envVarProduction] || fallback;
//     case "development":
//       return process.env[envVarDevelopment] || fallback;
//     default:
//       return process.env[envVarLocal] || fallback;
//   }
// };

// module.exports = {
//   api: {
//     API_URL: getEnvValue(
//       "REACT_APP_API_URL_PRODUCTION",
//       "REACT_APP_API_URL_DEVELOPMENT",
//       "REACT_APP_API_URL_LOCAL",
//       "https://api-staging.optiwarehouse.com",
//     ),
//     API_URL_ONEX: getEnvValue(
//       "REACT_APP_API_URL_ONEX_PRODUCTION",
//       "REACT_APP_API_URL_ONEX_DEVELOPMENT",
//       "REACT_APP_API_URL_ONEX_LOCAL",
//       "https://api-staging.onexapis.com",
//     ),
//   },
//   ws_url: getEnvValue(
//     "REACT_APP_WS_URL_PRODUCTION",
//     "REACT_APP_WS_URL_DEVELOPMENT",
//     "REACT_APP_WS_URL_LOCAL",
//     "wss://i308mln9pe.execute-api.ap-southeast-1.amazonaws.com/dev",
//   ),
// };

module.exports = {
  api: {
    API_URL: process.env.REACT_APP_API_URL || "https://api-staging.optiwarehouse.com",
    API_URL_ONEX: process.env.REACT_APP_API_URL_ONEX || "https://api-staging.onexapis.com",
  },
  ws_url: process.env.REACT_APP_WS_URL || "wss://i308mln9pe.execute-api.ap-southeast-1.amazonaws.com/dev",
};
